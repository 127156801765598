import React, { useEffect, useState, useCallback, useContext } from "react";
import { useHistory } from "react-router-dom";

import LoginService from "services/LoginService";
import { AppContext } from "./AppContext";

export const AccountContext = React.createContext();

export const AccountProvider = (props) => {
  const history = useHistory();

  const [{ sessionToken, ...state }, setState] = useState({});
  const [loading, setLoading] = useState(true);

  const { standard_login_url } = useContext(AppContext);

  const isAdmin = sessionToken?.adm === 1;

  // User functions
  const getParentAccountInformation = useCallback(async () => {
    const account = await LoginService.me();
    if (account) {
      const { subscription = false, profile = false } = account;
      setState((orig) => ({ ...orig, profile, subscription }));
    }
    setLoading(false);
  }, []);

  const updateProfile = (profile) => {
    setState((orig) => ({ ...orig, profile }));
  };

  const updateSubscription = (subscription) => {
    setState((orig) => {
      if (subscription === null) return { ...orig, subscription };

      const { remaining_balance, ..._subscription } = subscription || {};
      const newState = { ...orig, subscription: _subscription };
      if (remaining_balance !== null) {
        newState.profile.balance = remaining_balance;
      }

      return newState;
    });
  };

  // General functions
  const setSessionToken = (token) => {
    if (token) {
      sessionStorage.setItem("token", token);

      const parsedToken = parseSessionToken(token);
      if (parsedToken) {
        setState({ sessionToken: parsedToken });
        return;
      }
    }
    setState({});
  };

  const checkForSessionToken = () => {
    const token = sessionStorage.getItem("token");
    if (token) {
      const parsedToken = parseSessionToken(token);
      if (parseSessionToken) {
        setState({ sessionToken: parsedToken });
      }
    } else {
      setLoading(false);
    }
  };

  const parseSessionToken = (token) => {
    return JSON.parse(Buffer.from(token.split(".")[1], "base64"));
  };

  const getAccountInformation = useCallback(() => {
    if (!isAdmin) {
      getParentAccountInformation();
    } else {
      setLoading(false);
    }
  }, [getParentAccountInformation, isAdmin]);

  const isLoggedIn = useCallback(() => {
    if (sessionToken) {
      const now = Date.now();
      const expires = sessionToken.exp * 1000;
      if (now > expires) {
        history.push("/logout");
        return false;
      } else {
        return true;
      }
    }
    return false;
  }, [sessionToken, history]);

  const logout = () => {
    let expires = new Date(0);
    document.cookie = "Authorization=;path=/;expires=" + expires;
    sessionStorage.clear();

    setSessionToken(null);

    if (typeof window.Echo !== "undefined") {
      window.Echo.disconnect();
    }

    history.push(standard_login_url);
  };

  const mount = () => {
    checkForSessionToken();
  };
  useEffect(mount, []);

  useEffect(() => {
    if (isLoggedIn()) {
      getAccountInformation();
    }
  }, [sessionToken, isLoggedIn, getAccountInformation]);

  // output
  let value = {
    setSessionToken,
    isLoggedIn,
    logout,
  };
  if (isAdmin) {
    value = {
      isAdmin,
      ...value,
    };
  } else {
    value = {
      updateProfile,
      updateSubscription,
      ...state,
      ...value,
    };
  }

  if (loading) return null;

  return (
    <AccountContext.Provider value={value}>
      {props.children}
    </AccountContext.Provider>
  );
};
