import React, { useContext } from "react";
import { compose } from "recompose";
import styled from "styled-components";

import { AppContext } from "context/AppContext";

import withTheme from "composers/withTheme";

const Wrapper = styled.div`
  display: grid;
  align-content: center;
  row-gap: 45px;
  width: 100%;

  & > * {
    margin: 0;
  }
`;

const StatisticWrapper = compose(withTheme)(styled.div`
  display: grid;
  row-gap: 20px;
  grid-auto-rows: minmax(38px, auto);

  ${({ responsiveSize }) =>
    responsiveSize !== "desktop" &&
    `
    justify-content: center;
    justify-items: center;
    row-gap: 13px;
  `}
`);

const Statistic = compose(withTheme)(styled.div`
  display: grid;
  ${({ responsiveSize }) =>
    responsiveSize === "desktop"
      ? `
    grid-template-columns: 35px auto;
  `
      : `
    justify-items: center;
    row-gap: 13px;
  `}

  column-gap: 13px;
  align-items: center;

  color: #44b363;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  white-space: pre-wrap;
`);

const StatisticImage = compose(withTheme)(styled.span`
  ${({ icon }) =>
    icon &&
    `
    background-image: url("${icon}");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  `}

  justify-self: center;
  width: 36px;
  height: 36px;

  ${({ responsiveSize }) =>
    responsiveSize === "desktop" && `grid-column-start: 1;`}
`);
const StatisticText = compose(withTheme)(styled.span`
  ${({ responsiveSize }) =>
    responsiveSize === "desktop" && `grid-column-start: 2;`}
`);

const LaptopPromo = styled.div`
  position: relative;
  height: 205px;
  &::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 116%;
    right: -12px;
    background: ${({ src }) => `transparent url(${src}) no-repeat center`};
    background-size: cover;
  }
`;
LaptopPromo.defaultProps = { src: require("assets/images/laptop_promo.png") };

const PhonePromo = styled.img`
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 800;
  height: 264px;
`;
PhonePromo.defaultProps = { src: require("assets/images/phone_promo.png") };

const LearnMore = compose(withTheme)(styled.a`
  text-align: center;
  padding-right: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #767676;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`);

const PromoView = (props) => {
  const { responsiveSize, configKey = "standard" } = props;

  const { customizations } = useContext(AppContext);
  const {
    sidebar_title = "",
    sidebar_lines = [],
    sidebar_footer_url = "",
    sidebar_footer = "",
  } = customizations[configKey];

  return (
    <Wrapper>
      {responsiveSize !== "desktop" && <PhonePromo />}
      {sidebar_title && (
        <h3 style={{ textAlign: "center", paddingRight: "20px" }}>
          {sidebar_title}
        </h3>
      )}
      {sidebar_lines.length > 0 && (
        <StatisticWrapper>
          {sidebar_lines.map(({ img, text }, i) => (
            <Statistic key={i}>
              {img && <StatisticImage icon={img} />}
              <StatisticText>{text}</StatisticText>
            </Statistic>
          ))}
        </StatisticWrapper>
      )}
      {responsiveSize === "desktop" && <LaptopPromo />}
      {sidebar_footer && (
        <LearnMore
          component={sidebar_footer_url ? "a" : "span"}
          href={sidebar_footer_url || undefined}
        >
          {sidebar_footer}
        </LearnMore>
      )}
    </Wrapper>
  );
};

export default compose(withTheme)(PromoView);
