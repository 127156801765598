import React from "react";

import ResponsiveNavbar from "components/Navigation/ResponsiveNavbar";

// import PeopleIcon from "@material-ui/icons/People";
import SettingsIcon from "@material-ui/icons/Settings";
import PaletteIcon from "@material-ui/icons/Palette";
// import PersonIcon from "@material-ui/icons/Person";
import LogoutIcon from "@material-ui/icons/ExitToApp";

const AdminNavbar = (props) => {
  const links = [
    // {
    //   to: "/customers",
    //   label: "Registered Users",
    //   icon: <PeopleIcon />,
    // },
    {
      to: "/customize",
      label: "Customization",
      icon: <PaletteIcon />,
    },
    {
      to: "/site_config",
      label: "Site Configuration",
      icon: <SettingsIcon />,
    },
    // {
    //   to: "/my_account",
    //   label: "My Account",
    //   icon: <PersonIcon />,
    // },
    {
      type: "logout",
      label: "Logout",
      icon: <LogoutIcon />,
    },
  ];

  return <ResponsiveNavbar {...props} links={links} />;
};

export default AdminNavbar;
