import React, { useState } from "react";

import Button from "components/Input/Button";
import TextInput from "components/Input/TextInput";
import Field from "components/Layout/Field";
import LoadingContainer from "components/Loading/LoadingContainer";
import Form, { FormError as Error } from "components/LoginView/Input/Form";
import LoginService from "services/LoginService";

import LockIcon from "@material-ui/icons/Lock";
import PersonIcon from "@material-ui/icons/Person";

import { H2 } from "components/LoginView/Headings";
import Panel from "components/LoginView/Input/Panel";

const Login = (props) => {
  const [state, setState] = useState({
    login: "",
    password: "",
    error: "",
    prevent_submit: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setState((orig) => ({ ...orig, [name]: value }));
  };

  const login = async () => {
    if (!state.prevent_submit) {
      setState((orig) => ({ ...orig, prevent_submit: true }));
      var loggedIn = false;
      try {
        const result = await LoginService.studentLogin(
          state.login,
          state.password
        );
        if (!result.errors && result.login_url) {
          loggedIn = true;
          window.location.assign(result.login_url);
        } else {
          if (typeof result.errors === "object") {
            setState((orig) => ({
              ...orig,
              error: { ...result.errors, global: result.message },
            }));
          } else {
            setState((orig) => ({
              ...orig,
              error: { global: result.message },
            }));
          }
        }
      } catch (e) {
        console.error("No data returned:", e.message);
      } finally {
        if (!loggedIn) {
          setState((orig) => ({ ...orig, prevent_submit: false }));
        }
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    login();
  };

  return (
    <Panel>
      {state.prevent_submit && <LoadingContainer />}
      <div style={{ maxWidth: "250px" }}>
        <H2>Login</H2>
        <Form onSubmit={handleSubmit}>
          <div>
            <Field>
              <TextInput
                error={!!state.error}
                errorMessage={state.error?.login}
                icon={<PersonIcon />}
                placeholder='Login'
                name='login'
                id='login'
                onChange={handleChange}
                value={state.login}
              />
            </Field>
            <Field>
              <TextInput
                autoComplete='current_password'
                error={!!state.error}
                errorMessage={state.error?.password}
                icon={<LockIcon />}
                placeholder='Password'
                password={true}
                id='password'
                name='password'
                type='password'
                onChange={handleChange}
                value={state.password}
              />
            </Field>
            <Field>
              <Button
                disabled={state.prevent_submit}
                style={{ marginTop: "25px" }}
                type='submit'
              >
                Login
              </Button>
            </Field>
          </div>
          {state.error && state.error.global && (
            <Error>{state.error.global}</Error>
          )}
        </Form>
      </div>
    </Panel>
  );
};

export default Login;
