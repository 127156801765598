import React, { useContext } from "react";
import { Route, useHistory } from "react-router-dom";
import { compose } from "recompose";
import styled from "styled-components";

import withTheme from "composers/withTheme";
import BackArrow from "components/LoginView/Input/BackArrow";

import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Panel from "components/LoginView/Input/Panel";

import { PathContext } from "context/PathContext";
import FallbackSwitch from "components/Routing/FallbackSwitch";

const Container = compose(withTheme)(styled(Panel)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 500px;
`);

const PasswordRecovery = (props) => {
  const history = useHistory();
  const paths = useContext(PathContext);

  return (
    <Container>
      <BackArrow
        style={{ alignSelf: "flex-start" }}
        onClick={() => history.goBack()}
      />
      <FallbackSwitch fallback={`${paths.forgotPassword}/1`}>
        <Route
          path={`${paths.forgotPassword}/1`}
          render={(routerProps) => <Step1 {...routerProps} />}
        />
        <Route
          path={`${paths.forgotPassword}/2`}
          render={(routerProps) => <Step2 {...routerProps} />}
        />
        <Route
          path={`${paths.forgotPassword}/3`}
          render={(routerProps) => <Step3 {...routerProps} />}
        />
      </FallbackSwitch>
    </Container>
  );
};

export default PasswordRecovery;
