import React, { useContext } from "react";

import { Route, Switch } from "react-router-dom";

import StandardLandingView from "./StandardLandingView/StandardLandingView";
import StudentLandingView from "./StudentLandingView/StudentLandingView";

import { AppContext } from "context/AppContext";

const BaseLandingView = (props) => {
  const {
    standard_login_url,
    alternate_login_type,
    student_login_enabled,
    student_login_url,
  } = useContext(AppContext);

  // The order of the routes matters. '/' has to be last in the list
  let order;
  if (alternate_login_type === "standard") {
    order = ["standard", "student"];
  } else {
    order = ["student", "standard"];
  }

  const loginPaths = {
    standard: (
      <Route
        path={standard_login_url}
        render={(routerProps) => (
          <StandardLandingView {...props} {...routerProps} />
        )}
      />
    ),
    student: (
      <Route
        path={student_login_url}
        render={(routerProps) => (
          <StudentLandingView {...props} {...routerProps} />
        )}
      />
    ),
  };

  if (!student_login_enabled) {
    return <Switch>{loginPaths.standard}</Switch>;
  }

  return (
    <Switch>
      {loginPaths[order[0]]}
      {loginPaths[order[1]]}
    </Switch>
  );
};

export default BaseLandingView;
