import FS from "./FetchService";

export default class AccountService {
  static async get() {
    return await FS.get("/me");
  }

  static async refreshSubscription() {
    return await FS.get("/me/subscription");
  }

  static async update(first_name, last_name, email, phone_number, region) {
    const form = new FormData();
    form.append("first_name", first_name || "");
    form.append("last_name", last_name || "");
    form.append("email", email || "");
    form.append("phone_number", phone_number || "");
    form.append("region", region || "");

    return await FS.patch("/me", form);
  }
  static async changePassword(
    current_password,
    new_password,
    new_password_confirmation
  ) {
    const form = new FormData();
    form.append("current_password", current_password || "");
    form.append("new_password", new_password || "");
    form.append("new_password_confirmation", new_password_confirmation || "");

    return await FS.patch("/change_password", form);
  }
  static async getLoginUrl() {
    return await FS.get("/tusitawi_login_url");
  }
}
