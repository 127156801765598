import React, { useContext } from "react";

import AdminAccordion from "components/AdminAccordion/AdminAccordion";

import PromoView from "views/AdminView/Customization/PromoView";
import LogoCustomizeView from "views/AdminView/Customization/LogoCustomizeView";
import { H3 } from "components/AccountView/Headings";
import { AppContext } from "context/AppContext";

const CustomizeView = (props) => {
  const { student_login_enabled } = useContext(AppContext);

  return (
    <div style={{ width: "100%" }}>
      <H3>Standard Login</H3>
      <AdminAccordion title='Logo'>
        <LogoCustomizeView configKey='standard' />
      </AdminAccordion>
      <AdminAccordion title='Informational Sidebar (Login Page)'>
        <PromoView configKey='standard' />
      </AdminAccordion>
      {student_login_enabled && (
        <React.Fragment>
          <H3 style={{ marginTop: "25px" }}>Student Login</H3>
          <AdminAccordion title='Logo'>
            <LogoCustomizeView configKey='student' />
          </AdminAccordion>
          <AdminAccordion title='Informational Sidebar (Login Page)'>
            <PromoView configKey='student' />
          </AdminAccordion>
        </React.Fragment>
      )}
    </div>
  );
};

export default CustomizeView;
