import React, { useEffect, useContext } from "react";
import { Route } from "react-router-dom";
import { withContext, withState } from "recompose";
import { compose } from "redux";
import styled from "styled-components";

import FallbackSwitch from "components/Routing/FallbackSwitch";

import withTheme from "composers/withTheme";
import { string } from "prop-types";

import AccountView from "./views/AccountView/AccountView";
import AccountNavbar from "./views/AccountView/AccountNavbar";
import BaseLandingView from "./views/LandingView/BaseLandingView";

import { AppContext, AppProvider } from "./context/AppContext";
import { AccountContext, AccountProvider } from "./context/AccountContext";
import { NavigationProvider } from "context/NavigationContext";

import DebugMenu from "components/Debug/DebugMenu";
import AdminNavbar from "views/AdminView/AdminNavbar";
import AdminView from "views/AdminView/AdminView";

const RESPONSIVE_SIZES = [
  {
    name: "desktop",
    minWidth: Infinity,
  },
  {
    name: "tablet",
    minWidth: 1200,
  },
  {
    name: "phone",
    minWidth: 800,
  },
];

const BACKGROUND_COLOR = "white";

const Main = styled.div`
  min-height: 100vh;
  background-color: ${BACKGROUND_COLOR};
  display: flex;
  ${({ headerExists, headerHeight }) =>
    headerExists &&
    `
    padding-top: ${headerHeight};
  `}
  align-items: stretch;
`;

const TestingVersion = compose(withTheme)(styled.div`
  position: fixed;
  user-select: none;
  bottom: 0;
  right: 0;
  color: rgba(128, 128, 128, 0.4);
  font-weight: bold;
  text-align: right;
  padding: 1px;
  z-index: 99999;
  pointer-events: none;

  ${({ responsiveSize }) =>
    responsiveSize === "phone" &&
    `
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 5px;
  `}
`);

let FeVersion = "";
try {
  FeVersion = require("./version.json");
} catch (e) {
  console.error(e.message);
}

const Logout = (props) => {
  const { logout } = useContext(AccountContext);

  useEffect(logout, []);

  return null;
};

// Render the main view and navbar
const View = (props) => {
  const { HeaderView, BodyView, responsiveSize, ..._props } = props;
  const headerHeight = responsiveSize !== "desktop" ? "58px" : "75px";

  return (
    <Main headerExists={!!HeaderView} headerHeight={headerHeight}>
      {HeaderView && <HeaderView headerHeight={headerHeight} {..._props} />}
      <BodyView {..._props} />
    </Main>
  );
};

// Secondary component in order to have access to account context
const InnerApp = (props) => {
  const { isLoggedIn, isAdmin } = useContext(AccountContext);
  const { debug, apiVersion } = useContext(AppContext);

  return (
    <React.Fragment>
      <FallbackSwitch>
        <Route path='/logout' component={Logout} />
        <Route
          path='/'
          render={(routerProps) => {
            if (isLoggedIn()) {
              if (isAdmin) {
                return (
                  <NavigationProvider>
                    <View
                      HeaderView={AdminNavbar}
                      BodyView={AdminView}
                      {...props}
                      {...routerProps}
                    />
                  </NavigationProvider>
                );
              } else {
                return (
                  <NavigationProvider>
                    <View
                      HeaderView={AccountNavbar}
                      BodyView={AccountView}
                      {...props}
                      {...routerProps}
                    />
                    {debug && <DebugMenu />}
                  </NavigationProvider>
                );
              }
            } else {
              return (
                <View BodyView={BaseLandingView} {...props} {...routerProps} />
              );
            }
          }}
        />
      </FallbackSwitch>
      {apiVersion && (
        <TestingVersion>
          {FeVersion && <div>FE: {FeVersion}</div>}
          {apiVersion && <div>API: {apiVersion}</div>}
        </TestingVersion>
      )}
    </React.Fragment>
  );
};

const App = (props) => {
  const updateDimensions = () => {
    const windowWidth = typeof window !== "undefined" ? window.innerWidth : 0;
    // const windowHeight = typeof window !== "undefined" ? window.innerHeight : 0;

    let responsiveSize = "";
    for (let i in RESPONSIVE_SIZES) {
      const { name, minWidth } = RESPONSIVE_SIZES[i];
      if (windowWidth <= minWidth) {
        responsiveSize = name;
      }
    }

    if (props.responsiveSize !== responsiveSize) {
      props.setResponsiveSize(responsiveSize);
    }
  };

  const mount = () => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  };
  useEffect(mount, []);

  return (
    <AppProvider>
      <AccountProvider>
        <InnerApp />
      </AccountProvider>
    </AppProvider>
  );
};

export default compose(
  withState("responsiveSize", "setResponsiveSize", "desktop"),
  withContext({ responsiveSize: string }, ({ responsiveSize }) => ({
    responsiveSize,
  }))
)(App);
