import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useRef,
} from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

import styled from "styled-components";

import ExploreBundlesView from "./ExploreBundlesView";
import CheckoutView from "./CheckoutView";

import ScrollableArea from "components/Layout/ScrollableArea";
import ShopStepper from "components/ShopView/ShopStepper";

import { AccountContext } from "context/AccountContext";
import { PathContext } from "context/PathContext";

import SignUpView from "views/LandingView/StandardLandingView/SignUpView";
import ShopService from "services/ShopService";
import { H2 } from "components/LoginView/Headings";
import Button from "components/Input/Button";
import Plan from "components/ShopView/Plan";
import _Form from "components/LoginView/Input/Form";
import Field from "components/Layout/Field";
import LoginService from "services/LoginService";
import { Checkbox, FormControlLabel, Link as MuiLink } from "@material-ui/core";
import FlexButtonGroup from "components/Layout/FlexButtonGroup";
import TermsAndConditionsDialog from "components/ShopView/TermsAndConditionsDialog";

const Form = styled(_Form)`
  font-family: sans-serif;

  & > :not(:last-child) {
    margin-bottom: 20px;
  }
`;

const H2Centered = styled(H2)`
  padding: 0;
  margin: 0;
`;

const ShopView = (props) => {
  const { isLoggedIn, subscription, updateSubscription, setSessionToken } =
    useContext(AccountContext);

  const paths = useContext(PathContext);

  const history = useHistory();
  const location = useLocation();
  const { returnTo = "/account" } = location.state || {};

  const [step, setStep] = useState(1);

  const [editPlan, setEditPlan] = useState(false);

  const [state, setState] = useState({
    bundle: null,
    payment: null,
    user_info: null,
    order: null,
    promise: null,
    error: null,
    confirmPayment: false,
    confirmTerms: false,
    showTerms: false,
    prevent_submit: false,
  });

  const checkoutRef = useRef();

  const isUpgrade = subscription?.status === "active";

  const incrementStep = () => setStep((orig) => ++orig);

  const handleChange = useCallback(
    ({ target }) => {
      const { name, value } = target;
      setState((orig) => ({ ...orig, [name]: value }));
    },
    [setState]
  );

  const handleUpgrade = async () => {
    const order = await ShopService.purchaseUpgrade(
      state.bundle,
      state.payment
    );
    updateOrder(order);
    history.push(returnTo, { upgradeSuccess: true });
  };
  const handlePurchase = async () => {
    const order = await ShopService.makePurchase(state.bundle, state.payment);
    updateOrder(order);
    history.push("/account/receipt");
  };
  const updateOrder = (order) => {
    if (!order?.errors) {
      setState((orig) => ({ ...orig, order, promise: null }));
      updateSubscription(order);
    } else {
      setState((orig) => ({ ...orig, promise: null }));
    }
  };

  const signUp = async (payment) => {
    let redirecting = false;

    try {
      const result = await LoginService.signUp({
        ...state.user_info,
        bundle: state.bundle,
        payment: payment,
      });
      if (result) {
        if (!result.errors && result.access_token) {
          redirecting = true;
          setSessionToken(result.access_token, "/account/receipt");
        } else {
          setState((orig) => ({
            ...orig,
            error: { signup: { ...result.errors, global: result.message } },
          }));
        }
      }
    } catch (e) {
      console.error("No data returned:", e.message);
    } finally {
      if (!redirecting) {
        setState((orig) => ({ ...orig, prevent_submit: false }));
      }
    }
  };

  const handleConfirmPayment = (e) => {
    setState((orig) => ({ ...orig, confirmPayment: !orig.confirmPayment }));
  };

  const showTermsAndConditions = (e) => {
    e.preventDefault();
    setState((orig) => ({ ...orig, showTerms: true }));
  };

  const hideTermsAndConditions = (e) => {
    e.preventDefault();
    setState((orig) => ({ ...orig, showTerms: false }));
  };

  const handleConfirmTerms = (e) => {
    setState((orig) => ({ ...orig, confirmTerms: !orig.confirmTerms }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!canProceed || state.prevent_submit) return;
    setState((orig) => ({ ...orig, prevent_submit: true }));

    if (isLoggedIn() && isUpgrade) {
      handleUpgrade();
    } else {
      const { payment } = state;

      // Currently used to confirmPayment stripe card info
      if (checkoutRef?.current?.beforeSubmit) {
        payment.info = await checkoutRef.current.beforeSubmit();

        if (!payment.info || payment.info.error) {
          setState((orig) => ({
            ...orig,
            error: { checkout: payment.info?.error },
            prevent_submit: false,
          }));
          return;
        }
      }

      if (!isLoggedIn()) {
        signUp(payment);
      } else {
        handlePurchase();
      }
    }
  };

  const handleCancel = () => {
    if (step === 0 || window.confirm("Are you sure you want to cancel?")) {
      history.goBack();
    }
  };

  useEffect(() => {
    if (state.bundle !== null) {
      setStep((orig) => (orig === 1 ? 2 : orig));
      setState((orig) => ({ ...orig, confirmPayment: false }));
      setEditPlan(false);
    }
  }, [state.bundle]);

  const canProceed = state.confirmPayment && state.confirmTerms;

  const finalStep = isLoggedIn() ? 2 : 3;
  const isFinalStep = () => step >= finalStep;

  const ConfirmationChecks = (
    <React.Fragment>
      <hr />
      <FormControlLabel
        style={{ display: "flex", justifyContent: "flex-start", width: "100%" }}
        control={
          <Checkbox
            id='confirmUpgrade'
            color='primary'
            onClick={handleConfirmPayment}
            checked={state.confirmPayment}
          />
        }
        label={`I agree to ${
          isUpgrade ? "change plans and " : ""
        }pay the Total as shown above`}
      />
      <FormControlLabel
        style={{ display: "flex", justifyContent: "flex-start", width: "100%" }}
        control={
          <Checkbox
            id='confirmUpgrade'
            color='primary'
            onClick={handleConfirmTerms}
            checked={state.confirmTerms}
          />
        }
        label={
          <span>
            I accept Tusitawi{" "}
            <MuiLink onClick={showTermsAndConditions}>
              {"Terms & Conditions"}
            </MuiLink>
          </span>
        }
      />
    </React.Fragment>
  );

  return (
    <ScrollableArea
      centered
      style={{
        fontFamily: "sans-serif",
        maxWidth: "400px",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <TermsAndConditionsDialog
        open={state.showTerms}
        onClose={hideTermsAndConditions}
        onAccept={() =>
          setState((orig) => ({
            ...orig,
            confirmTerms: true,
            showTerms: false,
          }))
        }
        clickAway={false}
      />
      {isLoggedIn() && <H2>{isUpgrade ? "Change Plan" : "Sign Up"}</H2>}
      <Form onSubmit={handleSubmit}>
        <FlexButtonGroup>
          {isLoggedIn() && (
            <Button variant='outlined' onClick={handleCancel}>
              Cancel
            </Button>
          )}
          {step <= 0 && (
            <Button
              style={{
                flexGrow: "1",
                marginBottom: isLoggedIn() ? null : "6px",
              }}
              onClick={() => setStep(1)}
              color='secondary'
            >
              {isLoggedIn() ? "Begin" : "Begin Registration"}
            </Button>
          )}
        </FlexButtonGroup>
        {!isLoggedIn() && (
          <Link to={paths.base} style={{ color: "white", textAlign: "center" }}>
            Already registered? Click here to log in
          </Link>
        )}
        <ShopStepper
          step='1'
          currentStep={step}
          pre={<H2Centered>Select Plan</H2Centered>}
          post={
            editPlan ? (
              <React.Fragment>
                <ExploreBundlesView
                  name='bundle'
                  onSelect={handleChange}
                  upgrade={isUpgrade}
                />
                <FlexButtonGroup justifyContent='flex-end'>
                  <Button color='secondary' onClick={() => setEditPlan(false)}>
                    Cancel
                  </Button>
                </FlexButtonGroup>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {isUpgrade && (
                  <React.Fragment>
                    <H2>Your current Plan</H2>
                    <Plan
                      bundle={subscription}
                      hideButton={true}
                      disabled={true}
                    />
                  </React.Fragment>
                )}
                <H2>Your new Plan</H2>
                <Plan
                  bundle={state.bundle}
                  hideButton={true}
                  disabled={true}
                  variant={"inverted"}
                />
                <FlexButtonGroup justifyContent='flex-end'>
                  <Button color='secondary' onClick={() => setEditPlan(true)}>
                    Change Plan
                  </Button>
                </FlexButtonGroup>
              </React.Fragment>
            )
          }
          loading={state.prevent_submit}
        >
          <ExploreBundlesView
            name='bundle'
            onSelect={handleChange}
            upgrade={isUpgrade}
          />
        </ShopStepper>
        <ShopStepper
          step='2'
          currentStep={step}
          pre={<H2Centered>Payment Info</H2Centered>}
          loading={state.prevent_submit}
        >
          <CheckoutView
            name='payment'
            bundle={state.bundle}
            upgrade={isUpgrade}
            onChange={handleChange}
            checkoutText={props.checkoutText}
            ref={checkoutRef}
            disabled={state.prevent_submit}
            errors={state.error?.checkout}
            disableLoadingSpinner={true}
          />
          {step === 2 && (
            <React.Fragment>
              {isFinalStep() && ConfirmationChecks}
              <Field>
                {isFinalStep() ? (
                  <Button disabled={!canProceed} type='submit'>
                    Checkout
                  </Button>
                ) : (
                  <Button
                    disabled={state.prevent_submit}
                    onClick={incrementStep}
                  >
                    Proceed
                  </Button>
                )}
              </Field>
            </React.Fragment>
          )}
        </ShopStepper>
        {finalStep > 2 && (
          <ShopStepper
            step='3'
            currentStep={step}
            pre={<H2Centered>Account Info</H2Centered>}
            loading={state.prevent_submit}
          >
            <SignUpView
              name='user_info'
              onChange={handleChange}
              disabled={state.prevent_submit}
              errors={state.error?.signup}
            />
            {ConfirmationChecks}
            <Field style={{ marginTop: "20px" }}>
              <Button
                disabled={!canProceed || state.prevent_submit}
                type='submit'
              >
                Checkout
              </Button>
            </Field>
          </ShopStepper>
        )}
      </Form>
    </ScrollableArea>
  );
};

export default ShopView;
