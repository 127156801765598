import React, { useState, useEffect } from "react";
import { compose } from "recompose";

import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import withTheme from "composers/withTheme";

import uniqueId from "lodash/uniqueId";

const Dropdown = (props) => {
  const [uuid] = useState(uniqueId());

  const [state, setState] = useState({
    options: [],
    selection: "",
  });

  const {
    name,
    nullable,
    nullValue,
    disabled,
    error,
    label,
    loading,
    onChange,
    responsiveSize,
  } = props;

  // Load options
  useEffect(() => {
    const options = props.options;

    if (loading) return;

    if (!options?.length) return;

    const listOfOptions = options.map((option) => {
      const { label, value } = option;
      return { label: label || value, value };
    });

    if (nullable) {
      listOfOptions.unshift({
        label: nullValue || null,
        value: null,
      });
    }

    setState((orig) => ({
      ...orig,
      options: listOfOptions,
    }));
  }, [loading, props.options, nullable, nullValue]);

  // Read current value from props
  useEffect(() => {
    if (loading || state.options.length <= 0) return;

    const value = props.value;

    let selection;
    if (typeof value === "undefined" || value === null || value === "") {
      selection = "";
    } else {
      selection = state.options.find((option) => option.value === value);
      if (!selection) {
        selection = "";
      }
    }

    setState((orig) => ({ ...orig, selection }));
  }, [loading, state.options, props.value]);

  const handleSelectionChange = (e, selectedOption) => {
    let selection = null;
    if (selectedOption && selectedOption.value) {
      selection = selectedOption;
    }

    setState((orig) => ({
      ...orig,
      selection,
    }));

    onChange &&
      onChange({
        target: { name, value: selection?.value },
      });
  };

  return (
    <React.Fragment>
      <Autocomplete
        autoComplete
        autoHighlight
        id={`dropdown-${uuid}`}
        size={responsiveSize === "phone" ? "small" : "medium"}
        disabled={disabled}
        loading={loading}
        getOptionLabel={(option) => option.label || ""}
        renderInput={(params) =>
          !loading && (
            <TextField
              {...params}
              label={label}
              error={!!error}
              variant='outlined'
            />
          )
        }
        onChange={handleSelectionChange}
        options={state.options}
        getOptionSelected={(option, selection) =>
          !selection || option.value === selection?.value
        }
        value={state.selection}
      />
      {error && (
        <FormHelperText error id={`${uuid}-error-text`}>
          {error}
        </FormHelperText>
      )}
    </React.Fragment>
  );
};

export default compose(withTheme)(Dropdown);
