import React, { useContext, useMemo } from "react";
import styled from "styled-components";

import { AppContext } from "context/AppContext";

const Logo = styled.img`
  max-width: 325px;
  width: 100%;
  user-select: none;
`;

export default (props) => {
  const { src, type, nav = false, subtitle } = props;
  const { logos } = useContext(AppContext);
  const targetLogoUrl = logos[type];

  const logo = useMemo(() => {
    if (src) return src;

    if (targetLogoUrl) return targetLogoUrl;

    if (nav) {
      return require("assets/general/TusitawiNotRotated.svg");
    } else {
      return require("assets/general/TusitawiRotated.svg");
    }
  }, [src, nav, targetLogoUrl]);

  return <Logo {...props} subtitle={subtitle} src={logo} />;
};
