import React, { useState, useEffect, useImperativeHandle } from "react";
import { Elements as Stripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import ShopService from "services/ShopService";

import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import Field from "components/Layout/Field";
import TextInput from "components/Input/TextInput";
import StripeInput from "components/Input/StripeInput";

import PaymentIcon from "@material-ui/icons/Payment";
import ScheduleIcon from "@material-ui/icons/Schedule";
import PoundIcon from "mdi-material-ui/Pound";
import LoadingContainer from "components/Loading/LoadingContainer";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const NewPaymentMethodForm = React.forwardRef((props, ref) => {
  const stripe = useStripe();
  const elements = useElements();

  const [state, setState] = useState({
    name: "",
    cardnumber: false,
    cardexpiry: false,
    cardcvc: false,
    dirty: false,
  });

  const [stateErrors, _setErrors] = useState(
    typeof props.errors === "undefined" ? {} : "controlled"
  );
  const setErrors = (...args) => {
    return stateErrors !== "controlled" && _setErrors(...args);
  };

  const errors = stateErrors === "controlled" ? props.errors : stateErrors;

  const [cachedClientSecret, setClientSecret] = useState(null);
  const [loading, setLoading] = useState(false);

  const getNewClientSecret = async () => {
    const clientSecret = await ShopService.getStripeSetupSecret();
    setClientSecret(clientSecret.secret);

    return clientSecret.secret;
  };

  const validateFormCompletion = () => {
    let passed = true;
    const error = {};

    const generateErrorMessage = (field) => `Your ${field} is incomplete`;

    if (state.name.length === 0) {
      passed = false;
      error.name = "Your name is required";
    }
    if (!state.cardnumber) {
      passed = false;
      error.cardnumber = generateErrorMessage("card number");
    }
    if (!state.cardexpiry) {
      passed = false;
      error.cardexpiry = generateErrorMessage("card's expiration date");
    }
    if (!state.cardcvc) {
      passed = false;
      error.cardcvc = generateErrorMessage("card's security code");
    }

    setErrors(error);

    return {
      passed,
      error,
    };
  };

  const handleSetupIntent = async () => {
    if (!stripe || !elements) {
      return;
    }

    const validation = validateFormCompletion();
    if (!validation.passed) {
      return { error: validation.error };
    }

    let clientSecret = cachedClientSecret;
    if (!clientSecret) {
      clientSecret = await getNewClientSecret();
    }

    const currentIntent = await stripe.retrieveSetupIntent(clientSecret);

    if (currentIntent?.setupIntent?.status === "succeeded") {
      if (state.dirty) {
        clientSecret = await getNewClientSecret();
      } else {
        return currentIntent;
      }
    }

    const cardElement = elements.getElement(CardNumberElement);

    setLoading(true);
    const { error, setupIntent } = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        billing_details: { name: state.name },
        card: cardElement,
      },
    });
    setLoading(false);

    if (error) {
      console.error("[error]", error);
      return { error };
    } else {
      return setupIntent;
    }
  };

  const handlePaymentIntent = async () => {
    if (!stripe || !elements) {
      return;
    }

    const validation = validateFormCompletion();
    if (!validation.passed) {
      return { error: validation.error };
    }

    const cardElement = elements.getElement(CardNumberElement);

    setLoading(true);
    const { error, paymentIntent } = await stripe.confirmCardPayment(
      props.paymentIntent,
      {
        setup_future_usage: "on_session",
        payment_method: {
          billing_details: { name: state.name },
          card: cardElement,
        },
      }
    );
    setLoading(false);

    if (error) {
      console.error("[error]", error);
      return { error };
    } else {
      return paymentIntent;
    }
  };

  useImperativeHandle(ref, () => ({
    beforeSubmit: async () =>
      !!props.paymentIntent
        ? await handlePaymentIntent()
        : await handleSetupIntent(),
    clear: () => {
      if (elements) {
        elements.getElement(CardNumberElement).clear();
        elements.getElement(CardExpiryElement).clear();
        elements.getElement(CardCvcElement).clear();
      }

      setState({
        name: "",
        cardnumber: false,
        cardexpiry: false,
        cardcvc: false,
        dirty: false,
      });
    },
  }));

  const handleChange = (e) => {
    const { name, value } = e.target || {};

    if (name) {
      setState((orig) => ({ ...orig, [name]: value, dirty: true }));
    } else {
      setState((orig) => ({ ...orig, [e.name]: e.complete, dirty: true }));
    }
  };

  const { disableLoadingSpinner } = props;
  return (
    <React.Fragment>
      {!stripe && <LoadingContainer />}
      {!disableLoadingSpinner && stripe && loading && (
        <LoadingContainer delay={0} />
      )}
      <Field>
        <TextInput
          label="Name"
          type="text"
          name="name"
          onChange={handleChange}
          value={state.name}
          error={errors?.name}
          errorMessage={errors?.name}
        />
      </Field>
      <Field>
        <TextInput
          label="Card Number"
          name="cardnumber"
          icon={<PaymentIcon />}
          onChange={(e) => handleChange({ name: "cardnumber", ...e })}
          error={errors?.cardnumber}
          errorMessage={errors?.cardnumber}
          inputProps={{
            inputComponent: StripeInput,
            inputProps: {
              component: CardNumberElement,
              options: CARD_ELEMENT_OPTIONS,
            },
          }}
        />
      </Field>
      <Field>
        <TextInput
          label="Card Expires"
          name="cardexpiry"
          icon={<ScheduleIcon />}
          onChange={(e) => handleChange({ name: "cardexpiry", ...e })}
          error={errors?.cardexpiry}
          errorMessage={errors?.cardexpiry}
          inputProps={{
            inputComponent: StripeInput,
            inputProps: {
              component: CardExpiryElement,
              options: CARD_ELEMENT_OPTIONS,
            },
          }}
        />
      </Field>
      <Field>
        <TextInput
          label="CVC"
          name="cardcvc"
          icon={<PoundIcon />}
          onChange={(e) => handleChange({ name: "cardcvc", ...e })}
          error={errors?.cardcvc}
          errorMessage={errors?.cardcvc}
          inputProps={{
            inputComponent: StripeInput,
            inputProps: {
              component: CardCvcElement,
              options: CARD_ELEMENT_OPTIONS,
            },
          }}
        />
      </Field>
      {/* <Button type="submit">Add Payment Method</Button> */}
    </React.Fragment>
  );
});

const AddPaymentMethodForm = (props, ref) => {
  const [state, setState] = useState({
    stripePromise: null,
    stripeIntent: null,
  });

  const initStripe = async () => {
    const { key } = await ShopService.getStripeKey();

    setState({
      stripePromise: loadStripe(key),
    });
  };

  const mount = () => {
    initStripe();
  };
  useEffect(mount, []);

  return (
    <Stripe stripe={state.stripePromise}>
      <NewPaymentMethodForm {...props} ref={ref} />
    </Stripe>
  );
};

export default React.forwardRef(AddPaymentMethodForm);
