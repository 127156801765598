import React, { useState, useContext, useEffect } from "react";
import { compose } from "recompose";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

import _Avatar from "components/AccountView/Student/Avatar";
import LoadingContainer from "components/Loading/LoadingContainer";
import withTheme from "composers/withTheme";
import StudentService from "services/StudentService";
import AccountService from "services/AccountService";
import { H2 as _H2, H3 } from "components/LoginView/Headings";
import Button from "components/Input/Button";

import { AccountContext } from "context/AccountContext";
import { AppContext } from "context/AppContext";
import { MaybePluralize } from "helpers/Pluralizer";

const H1 = compose(withTheme)(styled.h1`
  font-weight: bold;
  font-size: ${({ responsiveSize }) =>
    responsiveSize === "desktop" ? "44px" : "36px"};
  margin: 0;
`);

const H2 = compose(withTheme)(styled(_H2)`
  font-weight: normal;
  font-size: ${({ responsiveSize }) =>
    responsiveSize === "desktop" ? "32px" : "22px"};
  margin: 0;
`);

const StudentListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
`;

const StudentList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
`;

const Avatar = styled(_Avatar)`
  width: 150px;
  min-width: 75px;
  margin: 20px;
  margin-bottom: 30px;
  cursor: pointer;
  background-size: 90%;
`;

const Student = styled(Avatar)`
  background-color: #fbb74d;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  transition: border 0.2s ease, box-shadow 0.2s ease, transform 0.2s ease;

  ${({ active }) =>
    active
      ? `
    :hover,
    :focus {
      transform: scale(1.15);
      box-shadow: 0px 0px 4px 5px rgba(0, 0, 0, 0.25);
      cursor: pointer;
    }
    :focus {
      border: 4px solid #000000;
      outline-width: 0;
    }
  `
      : `
      cursor: default;
      :focus {
        outline: none;
      }
      filter: grayscale(100%);
      user-select: none;
    `}
`;

const AddNew = styled(Avatar)`
  background-color: #168d44;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  transition: border 0.2s ease, box-shadow 0.2s ease, transform 0.2s ease;

  :hover,
  :focus {
    transform: scale(1.15);
    box-shadow: 0px 0px 4px 5px rgba(0, 0, 0, 0.25);
    cursor: pointer;
  }
  :focus {
    border: 4px solid #000000;
    outline-width: 0;
  }
`;

const ModalWindow = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 40px 0;

  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 40px;

  border: 1px solid rgba(33, 33, 33, 0.25);
  background-color: white;
`;

const TooManyStudentsNotification = (props) => {
  const history = useHistory();
  const location = useLocation();

  return (
    <ModalWindow>
      <H3 style={{ textAlign: "left", fontWeight: "bold" }}>
        Your plan has changed
      </H3>
      <p>
        You have too many students. Please deactivate {props.studentOverflow}{" "}
        {MaybePluralize("student", props.studentOverflow)} or upgrade your plan
      </p>
      <div style={{ textAlign: "right" }}>
        <Button
          style={{ margin: "15px 0" }}
          variant='text'
          onClick={() => history.push("/account/students")}
        >
          Manage Students
        </Button>
        <Button
          variant='text'
          onClick={() =>
            history.push("/account/bundles", { returnTo: location.pathname })
          }
        >
          Upgrade plan
        </Button>
      </div>
    </ModalWindow>
  );
};

const StudentListView = (props) => {
  const { subscription, profile } = useContext(AccountContext);
  const { tutor_enabled } = useContext(AppContext);

  const history = useHistory();

  const [state, setState] = useState({
    students: [],
    loaded: false,
    prevent_submit: true,
    open_modal: false,
  });

  const loadStudents = async () => {
    const students = await StudentService.getAll();
    setState((orig) => ({
      ...orig,
      students: students,
      loaded: true,
      prevent_submit: false,
    }));
  };
  const mount = () => {
    loadStudents();
  };
  useEffect(mount, []);

  const onTutorClick = async () => {
    if (state.prevent_submit) {
      return;
    }

    setState((orig) => ({ ...orig, prevent_submit: true }));

    const result = await AccountService.getLoginUrl();
    if (result && result.login_url) {
      window.location.assign(result.login_url);
    }

    setState((orig) => ({ ...orig, prevent_submit: false }));
  };

  const onStudentClick = async ({ student_id }) => {
    if (state.prevent_submit) {
      return;
    }

    setState((orig) => ({ ...orig, prevent_submit: true }));

    const result = await StudentService.getLoginUrl(student_id);
    if (result && result.login_url) {
      window.location.assign(result.login_url);
    }

    setState((orig) => ({ ...orig, prevent_submit: false }));
  };

  const studentDifference = state.students.length - subscription.students;
  const tooManyStudents = studentDifference > 0;

  return state.loaded ? (
    <React.Fragment>
      <H1>Welcome!</H1>
      <H2>
        {!!state.students.length
          ? "Who's Learning?"
          : "Add a student to begin using Tusitawi"}
      </H2>
      <span>
        {tutor_enabled && profile.has_tusitawi_id && (
          <React.Fragment>
            <StudentListContainer>
              <Student
                onClick={onTutorClick}
                label={`${profile.first_name} ${profile.last_name}`}
                active={true}
                color='#104f66'
                icon={require("assets/images/tutor_icon.svg")}
              />
            </StudentListContainer>
            <div
              style={{
                display: "block",
                height: "1px",
                width: "100%",
                marginTop: "25px",
                marginBottom: "10px",
                borderBottom: "1px solid #cacaca",
              }}
            />
          </React.Fragment>
        )}
        <StudentListContainer>
          {state.prevent_submit && <LoadingContainer />}
          <StudentList>
            {state.students.map((student) => (
              <Student
                key={student.student_id}
                onClick={() => !tooManyStudents && onStudentClick(student)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    onStudentClick(student);
                  }
                }}
                label={`${student.first_name} ${student.last_name}`}
                jsonData={student.avatar}
                tabIndex={0}
                active={!tooManyStudents}
              />
            ))}
            {(() => {
              if (subscription) {
                if (state.students.length < subscription.students) {
                  return (
                    <AddNew
                      onClick={() => history.push("/account/students/new")}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          history.push("/account/students/new");
                        }
                      }}
                      contentsStyle={{ fontSize: "100px", color: "white" }}
                      label='Add New'
                      showPlus
                      tabIndex={0}
                    />
                  );
                } else {
                  return null;
                }
              }
            })()}
          </StudentList>
        </StudentListContainer>
      </span>
      {tooManyStudents && (
        <TooManyStudentsNotification studentOverflow={studentDifference} />
      )}
    </React.Fragment>
  ) : (
    <LoadingContainer />
  );
};

export default StudentListView;
