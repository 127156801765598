import React, { useEffect, useState } from "react";
import _ from "lodash";

import { H3 } from "components/AccountView/Headings";
import Field from "components/Layout/Field";
import TextInput from "components/Input/TextInput";
import SettingsService from "services/SettingsService";
import LoadingContainer from "components/Loading/LoadingContainer";
import Button from "components/Input/Button";
import FlexButtonGroup from "components/Layout/FlexButtonGroup";
import HelpIcon from "@material-ui/icons/Help";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  FormControlLabel,
  Checkbox,
  IconButton,
  Tooltip,
  ClickAwayListener,
} from "@material-ui/core";
import StudentService from "services/StudentService";
// import Dropdown from "components/Input/Dropdown";

const GradesDialog = (props) => {
  const { open, onClose } = props;

  const [grades, setGrades] = useState({ loading: true });

  const loadGrades = async () => {
    setGrades({ loading: true });
    const grades = await StudentService.getAvailableGrades();
    setGrades(grades);
  };

  useEffect(() => {
    if (open) {
      loadGrades();
    }
  }, [open]);

  return (
    <Dialog onClose={onClose} open={open}>
      {grades.loading && <LoadingContainer />}
      <DialogTitle>Grades Test</DialogTitle>
      <DialogContent>
        {!grades.loading && (
          <p>
            {grades.length === 0
              ? "No grades were retrieved from the school. Ensure that all the platform and school " +
                "information is correct, then save and try again."
              : "If the list of grades below is incorrect, ensure that all the platform and school " +
                "information is correct, and that the school is setup properly on the platform side."}
          </p>
        )}
        {!grades.loading && grades.length > 0 && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Grade</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {grades.map((grade) => (
                <TableRow key={grade}>
                  <TableCell>{grade}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </DialogContent>
      <DialogActions>
        <Button color='secondary' onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const SettingsView = (props) => {
  const [state, setState] = useState({
    debug: false,
    phone_countries: "",
    platform: "",
    platform_key: "",
    platform_school_id: "",
    ga: "",
    stripe_key: "",
    stripe_secret: "",
    stripe_webhook: "",
    jamii_sms_url: "",
    jamii_sms_username: "",
    jamii_sms_password: "",
    jamii_sms_oauth_id: "",
    jamii_sms_oauth_secret: "",
    modified: {},
    waiting: true,
  });
  const [showGrades, setShowGrades] = useState(false);

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const loadSettings = async () => {
    const settings = await SettingsService.getSettings();
    setState((orig) => ({ ...orig, ...settings, waiting: false }));
  };
  const mount = () => {
    loadSettings();
  };
  useEffect(mount, []);

  const testGrades = () => {
    setShowGrades(true);
  };

  const handleChecked = ({ target: { name, checked } }) => {
    return handleChange({ target: { name, value: checked } });
  };

  const handleChange = ({ target: { name, value } }) => {
    setState((orig) => ({
      ...orig,
      modified: { ...orig.modified, [name]: true },
      [name]: value,
    }));
  };

  const handleSave = async () => {
    setState((orig) => ({ ...orig, waiting: true }));

    let data = {};
    if (Object.keys(state.modified).length > 0) {
      _.forEach(state.modified, (val, key) => {
        data[key] = state[key] || "";
      });
    }

    const result = await SettingsService.saveSettings(data);
    if (result?.success) {
      setState((orig) => ({ ...orig, modified: {}, waiting: false }));
    } else {
      setState((orig) => ({ ...orig, waiting: false }));
    }
  };

  const toggleTooltip = () => {
    setTooltipOpen((orig) => !orig);
  };
  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  return (
    <div style={{ width: "100%" }}>
      {state.waiting && <LoadingContainer />}
      <H3>General Settings</H3>
      <Field>
        <FormControlLabel
          style={{
            display: "flex",
            justifyContent: "flex-start",
            width: "100%",
          }}
          control={
            <Checkbox
              id='debugEnabled'
              color='primary'
              name='debug'
              onClick={handleChecked}
              checked={state.debug}
            />
          }
          label='Debug Mode Enabled'
        />
      </Field>
      {/* <Field>
        <Dropdown
          label="Account Identifier"
          name="account_identifier"
          onChange={handleChange}
          value={state.account_identifier}
          options={[
            { value: "phone_number", label: "Phone Number" },
            { value: "email", label: "E-Mail" },
          ]}
        />
      </Field> */}
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Field>
          <Tooltip
            title={
              <span>
                This must be a comma separated list of{" "}
                <em>
                  <a
                    href='https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2#Officially_assigned_code_elements'
                    target='_blank'
                    rel="noopener noreferrer"
                  >
                    ISO 3166-1 alpha-2
                  </a>
                </em>{" "}
                compliant country codes
              </span>
            }
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={tooltipOpen}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            interactive
          >
            <TextInput
              label='Phone Validation Countries'
              name='phone_countries'
              onChange={handleChange}
              value={state.phone_countries}
              inputProps={{
                endAdornment: (
                  <Button
                    onClick={toggleTooltip}
                    variant='text'
                    component={IconButton}
                  >
                    <HelpIcon color='primary' />
                  </Button>
                ),
              }}
            />
          </Tooltip>
        </Field>
      </ClickAwayListener>
      <H3>School Setup</H3>
      <Field>
        <TextInput
          disabled={state.waiting}
          onChange={handleChange}
          label='Platform URL'
          name='platform'
          value={state.platform}
        />
      </Field>
      <Field>
        <TextInput
          disabled={state.waiting}
          onChange={handleChange}
          label='Platform Key'
          name='platform_key'
          value={state.platform_key}
        />
      </Field>
      <Field>
        <TextInput
          disabled={state.waiting}
          onChange={handleChange}
          label='School ID'
          name='platform_school_id'
          value={state.platform_school_id}
        />
      </Field>
      <FlexButtonGroup justifyContent='flex-end'>
        <Button color='secondary' onClick={testGrades}>
          Test School Connection
        </Button>
      </FlexButtonGroup>
      <GradesDialog onClose={() => setShowGrades(false)} open={showGrades} />
      <H3>Google Analytics</H3>
      <Field>
        <TextInput
          disabled={state.waiting}
          onChange={handleChange}
          label='Google Analytics Tracking Key'
          name='ga'
          value={state.ga}
        />
      </Field>
      <H3>Stripe</H3>
      <Field>
        <TextInput
          disabled={state.waiting}
          onChange={handleChange}
          label='Stripe Publishable Key'
          name='stripe_key'
          value={state.stripe_key}
        />
      </Field>
      <Field>
        <TextInput
          disabled={state.waiting}
          onChange={handleChange}
          type='password'
          label='Stripe Secret Key'
          name='stripe_secret'
          value={state.stripe_secret}
        />
      </Field>
      <Field>
        <TextInput
          disabled={state.waiting}
          onChange={handleChange}
          type='password'
          label='Stripe Webhook Secret'
          name='stripe_webhook'
          value={state.stripe_webhook}
        />
      </Field>
      <H3>SMS (Jamii)</H3>
      <Field>
        <TextInput
          disabled={state.waiting}
          onChange={handleChange}
          label='URL'
          name='jamii_sms_url'
          value={state.jamii_sms_url}
        />
      </Field>
      <Field>
        <TextInput
          disabled={state.waiting}
          onChange={handleChange}
          label='Username'
          name='jamii_sms_username'
          value={state.jamii_sms_username}
        />
      </Field>
      <Field>
        <TextInput
          disabled={state.waiting}
          onChange={handleChange}
          label='Password'
          type='password'
          name='jamii_sms_password'
          value={state.jamii_sms_password}
        />
      </Field>
      <Field>
        <TextInput
          disabled={state.waiting}
          onChange={handleChange}
          label='OAuth ID'
          name='jamii_sms_oauth_id'
          value={state.jamii_sms_oauth_id}
        />
      </Field>
      <Field>
        <TextInput
          disabled={state.waiting}
          onChange={handleChange}
          type='password'
          label='OAuth Secret'
          name='jamii_sms_oauth_secret'
          value={state.jamii_sms_oauth_secret}
        />
      </Field>
      <Button color='primary' variant='contained' onClick={handleSave}>
        Save
      </Button>
    </div>
  );
};

export default SettingsView;
