import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import Button from "components/Input/Button";
import ButtonLink from "components/Input/ButtonLink";
import TextInput from "components/Input/TextInput";
import Field from "components/Layout/Field";
import LoadingContainer from "components/Loading/LoadingContainer";
import Form, { FormError as Error } from "components/LoginView/Input/Form";
import LoginService from "services/LoginService";

import LockIcon from "@material-ui/icons/Lock";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import { H2 } from "components/LoginView/Headings";
import { AppContext } from "context/AppContext";
import { AccountContext } from "context/AccountContext";
import { PathContext } from "context/PathContext";
import FreeTrial from "components/LoginView/FreeTrial";
import Panel from "components/LoginView/Input/Panel";

const ForgotPassword = styled(Link)`
  position: absolute;
  margin-top: 3px;
  top: 45%;
  right: 0;
  font-size: 12px;
  color: #767676;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const Login = (props) => {
  const paths = useContext(PathContext);

  const { accountRequirements, style } = useContext(AppContext);

  const { setSessionToken } = useContext(AccountContext);

  const [state, setState] = useState({
    login: "",
    password: "",
    error: "",
    prevent_submit: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setState((orig) => ({ ...orig, [name]: value }));
  };

  const login = async () => {
    if (!state.prevent_submit) {
      setState((orig) => ({ ...orig, prevent_submit: true }));
      var loggedIn = false;
      try {
        const result = await LoginService.login(state.login, state.password);
        if (!result.errors && result.access_token) {
          loggedIn = true;
          setSessionToken(result.access_token);
        } else {
          if (typeof result.errors === "object") {
            setState((orig) => ({
              ...orig,
              error: { ...result.errors, global: result.message },
            }));
          } else {
            setState((orig) => ({
              ...orig,
              error: { global: result.message },
            }));
          }
        }
      } catch (e) {
        console.error("No data returned:", e.message);
      } finally {
        if (!loggedIn) {
          setState((orig) => ({ ...orig, prevent_submit: false }));
        }
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    login();
  };

  let loginField;
  if (accountRequirements?.required === "email") {
    loginField = {
      state_value: "email",
      label: "E-Mail",
      options: {
        autoComplete: "email",
        icon: <EmailIcon />,
        type: "text",
      },
    };
  } else {
    loginField = {
      state_value: "phone_number",
      label: "Phone Number",
      options: {
        autoComplete: "tel_national",
        icon: <PhoneIcon />,
        type: "text",
      },
    };
  }

  return (
    <Panel>
      {state.prevent_submit && <LoadingContainer />}
      <div style={{ maxWidth: "250px" }}>
        {style === "trial" && <FreeTrial />}
        <H2>Login</H2>
        <Form onSubmit={handleSubmit}>
          <div>
            <Field>
              <TextInput
                {...loginField.options}
                error={!!state.error}
                errorMessage={state.error?.login}
                placeholder={loginField.label}
                name='login'
                id='login'
                onChange={handleChange}
                value={state.login}
              />
            </Field>
            <Field>
              <TextInput
                autoComplete='current_password'
                error={!!state.error}
                errorMessage={state.error?.password}
                icon={<LockIcon />}
                placeholder='Password'
                password={true}
                id='password'
                name='password'
                type='password'
                onChange={handleChange}
                value={state.password}
              />
              <ForgotPassword to={paths.forgotPassword}>
                Forgot password?
              </ForgotPassword>
            </Field>
            <Field>
              <Button
                disabled={state.prevent_submit}
                style={{ marginTop: "25px" }}
                type='submit'
              >
                Login
              </Button>
            </Field>
            <div style={{ margin: "30px 0 5px", textAlign: "center" }}>
              Don't have an account?
            </div>
            <Field>
              {style === "subscription" ? (
                <ButtonLink to={paths.shop} color='secondary' type='button'>
                  Select a Plan
                </ButtonLink>
              ) : (
                <ButtonLink to={paths.signup} color='secondary' type='button'>
                  Sign Up For a Free Trial
                </ButtonLink>
              )}
            </Field>
          </div>
          {state.error && state.error.global && (
            <Error>{state.error.global}</Error>
          )}
        </Form>
      </div>
    </Panel>
  );
};

export default Login;
