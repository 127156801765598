import React, { useState } from "react";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
} from "@material-ui/core";
import Button from "components/Input/Button";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const AdminAccordion = (props) => {
  const {
    defaultOpen = false,
    hideCollapseButton = false,
    title,
    children,
  } = props;

  const [open, setOpen] = useState(!!defaultOpen);
  const toggleOpen = () => {
    setOpen((orig) => !orig);
  };

  return (
    <Accordion variant='outlined' expanded={open} onChange={toggleOpen}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {title}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
      {!hideCollapseButton && (
        <AccordionActions>
          <Button onClick={() => setOpen(false)}>Collapse</Button>
        </AccordionActions>
      )}
    </Accordion>
  );
};

export default AdminAccordion;
