import React, { useState, useContext } from "react";
import { FormControlLabel, Checkbox, InputAdornment } from "@material-ui/core";
import Field from "components/Layout/Field";
import TextInput from "components/Input/TextInput";
import Button from "components/Input/Button";
import Dropdown from "components/Input/Dropdown";
import SettingsService from "services/SettingsService";
import LoadingContainer from "components/Loading/LoadingContainer";
import { AppContext } from "context/AppContext";

const LoginSettingsView = (props) => {
  const {
    student_login_enabled,
    alternate_login_type,
    alternate_login_url,
    updateApp,
  } = useContext(AppContext);
  const [state, setState] = useState({
    student_login_enabled: student_login_enabled,
    alternate_login_type: alternate_login_type || "student",
    alternate_login_url: alternate_login_url || "",
  });
  const [errors, setErrors] = useState();
  const [waiting, setWaiting] = useState(false);

  const handleChange = ({ target: { name, value } }) => {
    setState((orig) => ({ ...orig, [name]: value }));
  };
  const handleChecked = ({ target: { name, checked } }) => {
    setState((orig) => ({ ...orig, [name]: checked }));
  };

  const handleSave = async () => {
    setWaiting(true);
    const result = await SettingsService.saveSettings(state);
    if (result?.success) {
      updateApp(result.updates);
      setErrors({});
    } else if (result?.errors) {
      setErrors(result.errors);
    }
    setWaiting(false);
  };

  return (
    <div style={{ width: "100%" }}>
      {waiting && <LoadingContainer />}
      <Field>
        <FormControlLabel
          style={{
            display: "flex",
            justifyContent: "flex-start",
            width: "100%",
          }}
          control={
            <Checkbox
              color='primary'
              name='student_login_enabled'
              onClick={handleChecked}
              checked={state.student_login_enabled}
            />
          }
          label='Enable Student Login Page'
        />
      </Field>
      {state.student_login_enabled && (
        <React.Fragment>
          <Field>
            <Dropdown
              label='Alternate Login Page'
              id='alternate_login_type'
              name='alternate_login_type'
              onChange={handleChange}
              value={state.alternate_login_type}
              error={errors?.alternate_login_type}
              errorMessage={errors?.alternate_login_type}
              options={[
                { value: "student", label: "Student" },
                { value: "standard", label: "Standard" },
              ]}
            />
          </Field>
          <Field>
            <TextInput
              disabled={!state.student_login_enabled}
              value={state.alternate_login_url}
              name='alternate_login_url'
              label='Alternate Login URL'
              inputProps={{
                startAdornment: (
                  <InputAdornment position='start'>/</InputAdornment>
                ),
              }}
              onChange={handleChange}
              error={errors?.alternate_login_url}
              errorMessage={errors?.alternate_login_url}
            />
          </Field>
        </React.Fragment>
      )}
      <Button onClick={handleSave}>Save Login Style Settings</Button>
    </div>
  );
};

export default LoginSettingsView;
