import React, { useState, useEffect } from "react";

import SettingsService from "services/SettingsService";

import { FormControlLabel, Checkbox, ButtonGroup } from "@material-ui/core";
import Field from "components/Layout/Field";
import TextInput from "components/Input/TextInput";
import Button from "components/Input/Button";

import DeleteIcon from "@material-ui/icons/DeleteOutline";
import LoadingContainer from "components/Loading/LoadingContainer";

const TutorSettingsView = (props) => {
  const [state, setState] = useState({
    tutor_enabled: false,
    tutor_course_ids: [],
  });
  const [waiting, setWaiting] = useState(false);

  const loadSettings = async () => {
    const { tutor_enabled, tutor_course_ids = "" } =
      await SettingsService.getSettings();
    const tutor_course_ids_array = tutor_course_ids.split(",");
    setState((orig) => ({
      ...orig,
      tutor_enabled,
      tutor_course_ids: tutor_course_ids_array,
    }));
  };
  const mount = () => {
    loadSettings();
  };
  useEffect(mount, []);

  const handleChecked = ({ target: { name, checked } }) => {
    setState((orig) => ({ ...orig, [name]: checked }));
  };

  const handleCourseIDChanged =
    (i) =>
    ({ target: { value } }) => {
      setState((orig) => {
        const { tutor_course_ids } = orig;
        tutor_course_ids[i] = value;
        return { ...orig, tutor_course_ids };
      });
    };
  const handleCourseIDAdded = () => {
    setState((orig) => {
      const { tutor_course_ids } = orig;
      tutor_course_ids.push("");
      return { ...orig, tutor_course_ids };
    });
  };
  const handleCourseIDRemoved = (i) => () => {
    setState((orig) => {
      const { tutor_course_ids } = orig;
      tutor_course_ids.splice(i, 1);
      return { ...orig, tutor_course_ids };
    });
  };

  const handleSave = async () => {
    if (waiting) return;

    setWaiting(true);

    let tutor_course_ids = state.tutor_course_ids.join(",");
    await SettingsService.saveSettings({ ...state, tutor_course_ids });

    setWaiting(false);
  };

  return (
    <div style={{ width: "100%" }}>
      {waiting && <LoadingContainer />}
      <Field>
        <FormControlLabel
          style={{
            display: "flex",
            justifyContent: "flex-start",
            width: "100%",
          }}
          label='Tutor Student Account Enabled'
          control={
            <Checkbox
              color='primary'
              name='tutor_enabled'
              onClick={handleChecked}
              checked={state.tutor_enabled}
            />
          }
        />
      </Field>
      {state.tutor_course_ids.map((course_id, i) => (
        <Field key={i}>
          <TextInput
            value={course_id}
            name={`course_id_${i}`}
            label='Course ID'
            onChange={handleCourseIDChanged(i)}
          />
          <ButtonGroup>
            <Button onClick={handleCourseIDRemoved(i)}>
              <DeleteIcon style={{ color: "red" }} />
            </Button>
          </ButtonGroup>
        </Field>
      ))}
      <Field>
        <Button onClick={handleCourseIDAdded}>Add Course ID</Button>
      </Field>
      <Button onClick={handleSave}>Save Tutor Settings</Button>
    </div>
  );
};

export default TutorSettingsView;
