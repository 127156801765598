import React, { useState, useContext } from "react";
import styled from "styled-components";

import { AppContext } from "context/AppContext";
import { ButtonGroup, Input as _Input } from "@material-ui/core";
import Button from "components/Input/Button";
import { H3 } from "components/AccountView/Headings";
import ResourceService from "services/ResourceService";
import TusitawiLogo from "components/Logo/TusitawiLogo";

const Input = styled(_Input)`
  display: none;
`;

const LogoCustomizeView = (props) => {
  const { configKey = "standard" } = props;

  const { logos, updateLogos } = useContext(AppContext);

  const [state, setState] = useState({
    main_logo: "",
    nav_logo: "",
  });

  const handleFileChange = ({ target: { files, name } }) => {
    console.log(files, name);
    setState((orig) => ({ ...orig, [name]: files[0] }));
  };

  const handleUpload = (logo) => async () => {
    const result = await ResourceService.uploadLogo(
      `${configKey}_${logo}`,
      state[logo]
    );
    updateLogos(result);
    setState((orig) => ({ ...orig, [logo]: null }));
  };

  const handleDelete = (logo) => async () => {
    const result = await ResourceService.deleteLogo(logo);
    updateLogos(result);
    setState((orig) => ({ ...orig, [logo]: null }));
  };

  return (
    <div>
      <H3>Main Logo</H3>
      <TusitawiLogo type={`${configKey}_main_logo`} />
      <ButtonGroup color='primary' variant='contained'>
        <Button component='label' htmlFor={`${configKey}_main_logo`}>
          <Input
            accept='image/*'
            id={`${configKey}_main_logo`}
            type='file'
            name='main_logo'
            onChange={handleFileChange}
          />
          {state.main_logo ? state.main_logo.name : "Choose Image"}
        </Button>
        <Button disabled={!state.main_logo} onClick={handleUpload("main_logo")}>
          Upload
        </Button>
        <Button
          disabled={!logos[`${configKey}_main_logo`]}
          onClick={handleDelete("main_logo")}
        >
          Revert to Default
        </Button>
      </ButtonGroup>
      {configKey !== "student" && (
        <React.Fragment>
          <hr />
          <H3>Navbar Logo</H3>
          <TusitawiLogo type={`${configKey}_nav_logo`} nav />
          <ButtonGroup color='primary' variant='contained'>
            <Button component='label' htmlFor={`${configKey}_nav_logo`}>
              <Input
                accept='image/*'
                id={`${configKey}_nav_logo`}
                type='file'
                name='nav_logo'
                onChange={handleFileChange}
              />
              {state.nav_logo ? state.nav_logo.name : "Choose Image"}
            </Button>
            <Button
              disabled={!state.nav_logo}
              onClick={handleUpload("nav_logo")}
            >
              Upload
            </Button>
            <Button
              disabled={!logos[`${configKey}_nav_logo`]}
              onClick={handleDelete("nav_logo")}
            >
              Revert to Default
            </Button>
          </ButtonGroup>
        </React.Fragment>
      )}
    </div>
  );
};

export default LogoCustomizeView;
