import FS from "./FetchService";

export default class SettingsService {
  static async getSettings() {
    return await FS.get("/settings");
  }

  static async saveSettings(data) {
    const formData = JSON.stringify(data);

    return await FS.post("/settings", formData, {
      header: (headers) => headers.append("Content-Type", "application/json"),
    });
  }
}
