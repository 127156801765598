import React, { useState, useContext } from "react";

import { AppContext } from "context/AppContext";

import { FormControlLabel, Checkbox, ButtonGroup } from "@material-ui/core";
import TextInput from "components/Input/TextInput";
import Field from "components/Layout/Field";
import { H3 } from "components/AccountView/Headings";

import ArrowUpIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownIcon from "@material-ui/icons/ArrowDownward";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import Button from "components/Input/Button";
import IconSelector from "components/Input/IconSelector";
import AppService from "services/AppService";
import LoadingContainer from "components/Loading/LoadingContainer";

const PromoView = (props) => {
  const { configKey = "standard" } = props;
  const { customizations = {}, updateCustomizations } = useContext(AppContext);
  const {
    hide_sidebar = false,
    sidebar_title = "",
    sidebar_lines = [],
    sidebar_footer_url = "",
    sidebar_footer = "",
  } = customizations[configKey] || {};

  const [waiting, setWaiting] = useState(false);
  const [state, setState] = useState({
    hide_sidebar,
    sidebar_title,
    sidebar_lines,
    sidebar_footer_url,
    sidebar_footer,
  });

  const [errors, setErrors] = useState({});

  const handleChange = ({ target: { name, ...args } }) => {
    if ("value" in args) {
      setState((orig) => ({ ...orig, [name]: args.value }));
    } else if ("checked" in args) {
      setState((orig) => ({ ...orig, [name]: args.checked }));
    }
  };

  const handlePromoLineChanged =
    (index) =>
    ({ target: { name, value } }) => {
      setState((orig) => {
        const { sidebar_lines } = orig;
        sidebar_lines[index] = { ...sidebar_lines[index], [name]: value };
        return { ...orig, sidebar_lines };
      });
    };
  const handlePromoLineMovedUp = (index) => () => {
    setState((orig) => {
      const { sidebar_lines } = orig;

      if (index === 0) return orig;

      const tmp = sidebar_lines[index];
      sidebar_lines[index] = sidebar_lines[index - 1];
      sidebar_lines[index - 1] = tmp;

      return { ...orig, sidebar_lines };
    });
  };
  const handlePromoLineMovedDown = (index) => () => {
    setState((orig) => {
      const { sidebar_lines } = orig;

      if (index === sidebar_lines.length - 1) return orig;

      const tmp = sidebar_lines[index];
      sidebar_lines[index] = sidebar_lines[index + 1];
      sidebar_lines[index + 1] = tmp;

      return { ...orig, sidebar_lines };
    });
  };
  const handlePromoLineRemoved = (index) => () => {
    setState((orig) => {
      const { sidebar_lines } = orig;
      sidebar_lines.splice(index, 1);
      return { ...orig, sidebar_lines };
    });
  };
  const handlePromoLineAdded = () => {
    setState((orig) => {
      const { sidebar_lines } = orig;
      sidebar_lines.push({ img: "", text: "" });
      return { ...orig, sidebar_lines };
    });
  };

  const handleSave = async () => {
    setWaiting(true);
    const result = await AppService.saveFrontendCustomization({
      ...state,
      config_key: configKey,
    });
    if (result?.success) {
      updateCustomizations(result.customizations);
      setErrors({});
    } else if (result?.errors) {
      setErrors(result.errors);
    }
    setWaiting(false);
  };

  return (
    <div style={{ width: "100%" }}>
      {waiting && <LoadingContainer />}
      <Field>
        <FormControlLabel
          style={{
            display: "flex",
            justifyContent: "flex-start",
            width: "100%",
          }}
          control={
            <Checkbox
              id="confirmUpgrade"
              color="primary"
              name="hide_sidebar"
              onClick={handleChange}
              checked={state.hide_sidebar}
            />
          }
          label="Hide Sidebar"
        />
      </Field>
      <H3>Header</H3>
      <Field>
        <TextInput
          value={state.sidebar_title}
          name="sidebar_title"
          label={"Title"}
          onChange={handleChange}
          error={errors?.sidebar_title}
          errorMessage={errors?.sidebar_title}
        />
      </Field>
      <H3>Bullet Points</H3>
      {state.sidebar_lines.map(({ img, text }, i) => (
        <Field key={i}>
          <IconSelector
            name="img"
            value={img}
            onChange={handlePromoLineChanged(i)}
          />
          <TextInput
            inputProps={{ multiline: true }}
            value={text}
            name="text"
            label={`Line ${i + 1}`}
            onChange={handlePromoLineChanged(i)}
          />
          <ButtonGroup>
            <Button onClick={handlePromoLineMovedUp(i)}>
              <ArrowUpIcon />
            </Button>
            <Button onClick={handlePromoLineMovedDown(i)}>
              <ArrowDownIcon />
            </Button>
            <Button onClick={handlePromoLineRemoved(i)}>
              <DeleteIcon style={{ color: "red" }} />
            </Button>
          </ButtonGroup>
        </Field>
      ))}
      <Field>
        <Button onClick={handlePromoLineAdded}>Add New Line</Button>
      </Field>
      <H3>Footer</H3>
      <Field>
        <TextInput
          value={state.sidebar_footer_url}
          name="sidebar_footer_url"
          label="Footer URL"
          onChange={handleChange}
          error={errors?.sidebar_footer_url}
          errorMessage={errors?.sidebar_footer_url}
        />
      </Field>
      <Field>
        <TextInput
          value={state.sidebar_footer}
          name="sidebar_footer"
          label="Footer Text"
          onChange={handleChange}
          error={errors?.sidebar_footer}
          errorMessage={errors?.sidebar_footer}
        />
      </Field>
      <Button onClick={handleSave}>Save Sidebar</Button>
    </div>
  );
};

export default PromoView;
