import React from "react";
import { compose } from "recompose";
import styled from "styled-components";

import { Route } from "react-router-dom";

import _Panel from "components/AccountView/Input/Panel";
import ScrollableArea from "components/Layout/ScrollableArea";
import withTheme from "composers/withTheme";

import FallbackSwitch from "components/Routing/FallbackSwitch";
// import CustomersView from "views/AdminView/CustomersView";
import CustomizeView from "views/AdminView/Customization/CustomizeView";
import SiteConfigView from "views/AdminView/SiteConfig/SiteConfigView";
// import MyAccountView from "views/AdminView/MyAccountView";

const Panel = compose(withTheme)(styled(_Panel)`
  ${({ responsiveSize }) =>
    responsiveSize !== "phone" &&
    `
    padding: 45px;
    min-width: 600px;
  `};
`);

const AdminView = (props) => {
  const { responsiveSize } = props;

  return (
    <ScrollableArea centered style={{ fontFamily: "sans-serif" }}>
      <Panel invisible={responsiveSize === "phone" ? 1 : 0}>
        <FallbackSwitch fallback="/site_config">
          {/* <Route path="/customers" component={CustomersView} /> */}
          <Route path="/customize" component={CustomizeView} />
          <Route path="/site_config" component={SiteConfigView} />
          {/* <Route path="/my_account" component={MyAccountView} /> */}
        </FallbackSwitch>
      </Panel>
    </ScrollableArea>
  );
};

export default compose(withTheme)(AdminView);
