import React, { useEffect, useContext } from "react";
import { Route, Switch } from "react-router-dom";
import { compose } from "recompose";
import styled from "styled-components";

import TusitawiLogo from "components/Logo/TusitawiLogo";
import withTheme from "composers/withTheme";

import LoginView from "../StudentLandingView/StudentLoginView";
import PromoView from "../PromoView";

import { PathProvider, PathContext } from "context/PathContext";
import { AppContext } from "context/AppContext";

const SplitView = compose(withTheme)(styled.div`
  min-height: 100%;
  width: 100%;
  display: flex;
  ${({ responsiveSize }) =>
    responsiveSize !== "desktop" && `flex-direction: column-reverse;`}
  &>* {
    padding: 20px 0;
  }
`);

const InfoWrapper = compose(withTheme)(styled.div`
  position: relative;
  ${({ responsiveSize }) =>
    responsiveSize === "desktop"
      ? `
      width: 470px;
  `
      : `
      padding-top: 150px;
      padding-right: 30px;
      padding-left: 30px;
      text-align: center;
  `}
`);

const Info = compose(withTheme)(styled.div`
  ${({ responsiveSize }) =>
    responsiveSize === "desktop" &&
    `
      position: fixed;
      width: inherit;
      top: 50%;
      transform: translateY(-50%);
      padding-right: 30px;
      padding-left: 40px;
  `}
`);

const Contents = compose(withTheme)(styled.div`
  min-height: 100vh;
  flex-grow: 1;
  position: relative;
  background: linear-gradient(180deg, #318f4b 0%, #46b866 100%);

  display: grid;
  row-gap: 15px;
  grid-template-rows: min-content auto;
  justify-items: center;
  align-items: center;

  ${({ responsiveSize }) =>
    responsiveSize === "phone"
      ? `
    padding: 10px;
  `
      : `
    padding: 20px;
  `}
  ${({ responsiveSize }) =>
    responsiveSize !== "desktop" && `padding-bottom: 132px;`}
`);

const StudentLandingView = (props) => {
  const {
    customizations: {
      student: { hide_sidebar },
    },
  } = useContext(AppContext);
  const paths = useContext(PathContext);

  const mount = () => {
    window.scroll({ top: 0 });
  };
  useEffect(mount, []);

  return (
    <SplitView>
      {!hide_sidebar && (
        <InfoWrapper>
          <Info>
            <PromoView configKey='student' />
          </Info>
        </InfoWrapper>
      )}
      <Contents>
        <TusitawiLogo type='student_main_logo' />
        <Switch>
          <Route path={paths.base} component={LoginView} />
        </Switch>
      </Contents>
    </SplitView>
  );
};

export default (props) => (
  <PathProvider base={props.match?.path} paths={{}}>
    <StudentLandingView {...props} />
  </PathProvider>
);
