import React from "react";

import AdminAccordion from "components/AdminAccordion/AdminAccordion";

import RegionListView from "views/AdminView/SiteConfig/RegionListView";
import SettingsView from "views/AdminView/SiteConfig/SettingsView";
import LoginSettingsView from "views/AdminView/SiteConfig/LoginSettingsView";
import TutorSettingsView from "views/AdminView/SiteConfig/TutorSettingsView";

const SiteConfigView = (props) => {
  return (
    <React.Fragment>
      <AdminAccordion title='Settings'>
        <SettingsView />
      </AdminAccordion>
      <AdminAccordion title='Region List'>
        <RegionListView />
      </AdminAccordion>
      <AdminAccordion title='Login Style Settings'>
        <LoginSettingsView />
      </AdminAccordion>
      <AdminAccordion title='Tutor Settings'>
        <TutorSettingsView />
      </AdminAccordion>
    </React.Fragment>
  );
};

export default SiteConfigView;
