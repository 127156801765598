import React, { useState, useContext } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import Button from "components/Input/Button";
import TextInput from "components/Input/TextInput";
import Field from "components/Layout/Field";
import LoadingContainer from "components/Loading/LoadingContainer";
import _Form, { FormError as Error } from "components/LoginView/Input/Form";
import LoginService from "services/LoginService";

import PersonWavingIcon from "@material-ui/icons/EmojiPeople";
import PeopleIcon from "@material-ui/icons/People";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";

import { AppContext } from "context/AppContext";
import { PathContext } from "context/PathContext";

const Form = styled(_Form)`
  max-width: 250px;
  margin-top: 30px;
`;

const Step1 = (props) => {
  const history = useHistory();

  const { accountRequirements } = useContext(AppContext);
  const paths = useContext(PathContext);

  const [state, setState] = useState({
    login: "",
    first_name: "",
    last_name: "",
    error: "",
    prevent_submit: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((orig) => ({ ...orig, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    requestPasswordRecovery();
  };

  const requestPasswordRecovery = async () => {
    if (!state.prevent_submit) {
      setState((orig) => ({ ...orig, prevent_submit: true }));
      try {
        const result = await LoginService.requestPasswordRecovery(
          state.login,
          state.first_name,
          state.last_name
        );
        if (result) {
          if (!result.errors && result.verification && result.login) {
            // store verification and login in session storage
            sessionStorage.setItem("pw1_verification", result.verification);
            sessionStorage.setItem("pw1_login", result.login);
            history.push(`${paths.forgotPassword}/2`);
          } else {
            setState((orig) => ({
              ...orig,
              error: { ...result.errors, global: result.message },
            }));
          }
        }
      } catch (e) {
        console.error("No data returned:", e.message);
      } finally {
        setState((orig) => ({ ...orig, prevent_submit: false }));
      }
    }
  };

  let loginPrompt;
  if (accountRequirements?.required === "email") {
    loginPrompt = {
      icon: <EmailIcon />,
      label: "E-Mail",
    };
  } else {
    loginPrompt = {
      icon: <PhoneIcon />,
      label: "Phone Number",
    };
  }

  return (
    <React.Fragment>
      {state.prevent_submit && <LoadingContainer />}
      <h3>Forgot your password?</h3>
      <div>
        Fill in the following information to receive a unique code, allowing you
        to reset your password.
      </div>
      <Form onSubmit={handleSubmit}>
        <div>
          <Field>
            <TextInput
              errorColor='red'
              icon={loginPrompt.icon}
              placeholder={loginPrompt.label}
              type='text'
              id='login'
              name='login'
              onChange={handleChange}
              value={state.login}
              error={!!state.error?.login}
              errorMessage={state.error?.login}
            />
          </Field>
          <Field>
            <TextInput
              errorColor='red'
              icon={<PersonWavingIcon />}
              placeholder='First Name'
              type='text'
              id='first_name'
              name='first_name'
              onChange={handleChange}
              value={state.first_name}
              error={!!state.error?.first_name}
              errorMessage={state.error?.first_name}
            />
          </Field>
          <Field>
            <TextInput
              errorColor='red'
              icon={<PeopleIcon />}
              placeholder='Last Name'
              type='text'
              id='last_name'
              name='last_name'
              onChange={handleChange}
              value={state.last_name}
              error={!!state.error?.last_name}
              errorMessage={state.error?.last_name}
            />
          </Field>
          <Field>
            <Button green disabled={state.prevent_submit} type='submit'>
              Send me the code
            </Button>
          </Field>
        </div>
        {state.error && state.error.global && (
          <Error>{state.error.global}</Error>
        )}
      </Form>
    </React.Fragment>
  );
};

export default Step1;
