import React, { useState, useEffect } from "react";

export const PathContext = React.createContext();

export const PathProvider = (props) => {
  const [paths, setPaths] = useState(false);

  useEffect(() => {
    let prefix = props.base || "/";

    if (prefix.length === 0 || prefix[prefix.length - 1] !== "/") {
      prefix = `${prefix}/`;
    }

    let absPaths = {};

    for (let key in props.paths) {
      absPaths[key] = `${prefix}${props.paths[key]}`;
    }

    setPaths(absPaths);
  }, [props.paths, props.base]);

  if (!paths) return null;

  return (
    <PathContext.Provider
      value={{
        ...paths,
        base: props.base,
      }}
    >
      {props.children}
    </PathContext.Provider>
  );
};
