import React, { useContext, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import Button from "components/Input/Button";
import TextInput from "components/Input/TextInput";
import Field from "components/Layout/Field";
import LoadingContainer from "components/Loading/LoadingContainer";
import _Form, { FormError as Error } from "components/LoginView/Input/Form";
import LoginService from "services/LoginService";

import { AppContext } from "context/AppContext";
import { PathContext } from "context/PathContext";

const Form = styled(_Form)`
  max-width: 250px;
  margin-top: 25px;
`;

const Footer = styled.div`
  margin-top: 50px;
  font-size: 14px;
`;

const FooterButton = styled(Button)`
  width: 60%;
  height: auto;
  padding: 5px;
  margin-top: 5px;
`;

const Step2 = (props) => {
  const history = useHistory();
  const paths = useContext(PathContext);

  const login = sessionStorage.getItem("pw1_login");
  const verification = sessionStorage.getItem("pw1_verification");
  if (!login || !verification) {
    history.replace(`${paths.forgotPassword}/1`);
  }

  const { accountRequirements } = useContext(AppContext);

  const [state, setState] = useState({
    login: login, // Retrieved from session storage
    verification: verification,
    unique_code: "",
    error: "",
    prevent_submit: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((orig) => ({ ...orig, [name]: value }));
  };

  const verifyCode = async () => {
    if (!state.prevent_submit) {
      setState((orig) => ({ ...orig, prevent_submit: true }));
      try {
        // Retrieve verification from session storage
        const result = await LoginService.verifyCode(
          state.verification,
          state.unique_code
        );
        if (!result.errors && result.verification) {
          sessionStorage.clear();
          sessionStorage.setItem("pw2_verification", result.verification);
          history.push(`${paths.forgotPassword}/3`);
        } else {
          setState((orig) => ({
            ...orig,
            error: { ...result.errors, global: result.message },
          }));
        }
      } catch (e) {
        console.error("No data returned:", e.message);
      } finally {
        setState((orig) => ({ ...orig, prevent_submit: false }));
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    verifyCode();
  };

  return (
    <React.Fragment>
      {state.prevent_submit && <LoadingContainer />}
      <h3>Recovery Code Sent!</h3>
      <div style={{ marginBottom: "30px" }}>
        A unique code was just sent to {state.login}.<br />
        It may take a couple minutes to arrive.
      </div>
      <div>Input the unique code that was sent to you.</div>
      <Form onSubmit={handleSubmit}>
        <div>
          <Field>
            <TextInput
              placeholder='Unique Code'
              type='text'
              id='unique_code'
              name='unique_code'
              onChange={handleChange}
              value={state.unique_code}
              error={!!state.error}
              errorMessage={state.error?.unique_code}
            />
          </Field>
          <Field>
            <Button green disabled={state.prevent_submit} type='submit'>
              Continue
            </Button>
          </Field>
        </div>
        {state.error && state.error.global && (
          <Error>{state.error.global}</Error>
        )}
      </Form>
      <Footer>
        <div style={{ marginBottom: "30px" }}>
          Didn't receive a code?
          <br />
          Make sure your{" "}
          {accountRequirements?.required === "email"
            ? "e-mail"
            : "phone number"}{" "}
          is correct.
        </div>
        <FooterButton
          green
          disabled={state.prevent_submit}
          onClick={() => history.push(`${paths.forgotPassword}/1`)}
          type='button'
        >
          Resend {accountRequirements?.required === "email" ? "E-mail" : "Text"}
        </FooterButton>
      </Footer>
    </React.Fragment>
  );
};

export default Step2;
